import { TextFieldModule } from "@angular/cdk/text-field";
import { DatePipe, NgClass } from "@angular/common";
import { Component, OnInit, signal, ViewEncapsulation, WritableSignal } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { FuseDrawerComponent } from "@fuse/components/drawer";
import { GeneralChatService } from "./general-chat.service";

@Component({
    selector: 'general-chat',
    templateUrl: './general-chat.component.html',
    styles: `
        general-chat {
            position: static;
            display: block;
            flex: none;
            width: auto;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseDrawerComponent,
        NgClass,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        DatePipe,
    ],
})
export class GeneralChatComponent implements OnInit {

    showChatButton: WritableSignal<boolean>;
    conversation: WritableSignal<any> = signal(null);

    constructor(
        private generalChatService: GeneralChatService,
    ) { }

    ngOnInit(): void
    {
        this.showChatButton = this.generalChatService.showChatButton;
        this.conversation.set({
            accountId: 'me',
            messages: [
                {
                    id: '1',
                    accountId: 'me',
                    username: 'Alex',
                    isMine: true,
                    message: 'Hola',
                    createdAt: '2024-09-19 18:08:00',
                },
                {
                    id: '2',
                    accountId: 'notme',
                    username: 'AI',
                    isMine: false,
                    message: 'Hola, en que puedo ayudarte?',
                    createdAt: '2024-09-19 18:08:00',
                },
            ],
        });
    }

    sendMessage(message: string)
    {
        console.log('Message sent:', message);
    }
}
