@if (showChatButton())
{
    <button
        mat-fab
        aria-label="Open chat"
        class="fixed bottom-5 right-5 z-90"
        (click)="chatDrawer.toggle()"
    >
        <mat-icon
            class="text-white icon-size-5"
            svgIcon="mat_solid:forum"
        >
        </mat-icon>
    </button>
}

<fuse-drawer
    class="z-999 w-screen min-w-screen sm:w-100 sm:min-w-100"
    fixed
    [mode]="'over'"
    [name]="'chatDrawer'"
    [position]="'right'"
    #chatDrawer
>
    <div class="relative bg-card flex w-full flex-col overflow-auto">
        <button
            mat-icon-button
            class="absolute top-2 right-2 bg-white"
            (click)="chatDrawer.close()"
        >
            <mat-icon
                class="text-current"
                svgIcon="mat_solid:close"
            >
            </mat-icon>
        </button>

        <div class="flex flex-auto flex-col h-full max-h-full min-w-72 overflow-hidden bg-white dark:bg-transparent">
            @if (conversation())
            {
                <div
                    class="flex flex-col-reverse overflow-y-auto overscroll-y-contain grow"
                    (scroll)="handleScroll($event)"
                >
                    <div class="flex flex-auto shrink flex-col p-4">
                        @for (
                            message of conversation().messages;
                            track message.id;
                            let i = $index;
                            let first = $first;
                            let last = $last
                        ) {
                            <!-- Start of the day -->
                            @if (
                                first ||
                                (conversation().messages[i - 1].createdAt | date: 'd') !== (message.createdAt | date: 'd')
                            ) {
                                <div class="-mx-4 my-3 flex items-center justify-center">
                                    <div class="flex-auto border-b"></div>
                                    <div class="text-secondary mx-4 flex-0 text-sm font-medium leading-5">
                                        {{ message.createdAt | date: 'longDate' }}
                                    </div>
                                    <div class="flex-auto border-b"></div>
                                </div>
                            }
                            <div
                                class="flex flex-col"
                                [ngClass]="{
                                    'items-end': message.isMine,
                                    'items-start': !message.isMine,
                                    'mt-0.5':
                                        i > 0 &&
                                        conversation().messages[i - 1].isMine ===
                                            message.isMine,
                                    'mt-3':
                                        i > 0 &&
                                        conversation().messages[i - 1].isMine !==
                                            message.isMine,
                                }"
                            >
                                <div
                                    class="flex w-full items-end"
                                    [ngClass]="{
                                        'justify-end': message.isMine,
                                        'justify-start': !message.isMine,
                                    }"
                                >
                                    <!-- Avatar -->
                                    @if (!message.isMine && false)
                                    {
                                        <div
                                            class="mr-3 h-8 w-8 rounded-full bg-gray-300"
                                            [ngClass]="{
                                                'pointer-events-none opacity-0':
                                                    !(
                                                        last ||
                                                        conversation().messages[i + 1]
                                                            .isMine !==
                                                            message.isMine
                                                    ),
                                                'opacity-100':
                                                    last ||
                                                    conversation().messages[i + 1]
                                                        .isMine !==
                                                        message.isMine,
                                            }"
                                        >
                                        </div>
                                    }

                                    <!-- Bubble -->
                                    <div
                                        class="relative max-w-3/4 rounded-lg px-3 py-2"
                                        [ngClass]="{
                                            'bg-blue-500 text-blue-50':
                                                message.isMine,
                                            'bg-gray-500 text-gray-50':
                                                !message.isMine,
                                        }"
                                    >
                                        <!-- Speech bubble tail -->
                                        @if (
                                            last ||
                                            conversation().messages[i + 1].isMine !== message.isMine
                                        ) {
                                            <div
                                                class="absolute bottom-0 w-3"
                                                [ngClass]="{
                                                    '-right-1 -mr-px mb-px text-blue-500':
                                                        message.isMine,
                                                    '-left-1 -ml-px mb-px -scale-x-1 text-gray-500':
                                                        !message.isMine,
                                                }"
                                            >
                                                <svg
                                                    width="100%"
                                                    height="100%"
                                                    viewBox="0 0 66 66"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g
                                                        id="Page-1"
                                                        stroke="none"
                                                        stroke-width="1"
                                                        fill="none"
                                                        fill-rule="evenodd"
                                                    >
                                                        <path
                                                            d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                                                            fill="currentColor"
                                                            fill-rule="nonzero"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        }

                                        <!-- Username -->
                                        @if (
                                            !message.isMine &&
                                            (first || conversation().messages[i - 1].isMine !== message.isMine)
                                        ) {
                                            <div
                                                class="mb-1 text-sm font-medium leading-none"
                                                [innerHTML]="message.username"
                                            >
                                            </div>
                                        }

                                        <!-- Message -->
                                        <div
                                            class="min-w-4 leading-5"
                                            [innerHTML]="message.message"
                                        >
                                        </div>
                                    </div>
                                </div>

                                <!-- Time -->
                                @if (
                                    first ||
                                    last ||
                                    conversation().messages[i + 1].isMine !== message.isMine ||
                                    conversation().messages[i + 1].createdAt !== message.createdAt
                                ) {
                                    <div
                                        class="text-secondary my-0.5 text-sm font-medium"
                                        [ngClass]="{
                                            'mr-0': message.isMine,
                                            'ml-0': !message.isMine,
                                        }"
                                    >
                                        {{ message.createdAt | date: 'HH:mm' }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>

                <!-- Message field -->
                <div
                    class="flex items-end border-t bg-gray-50 p-4 dark:bg-transparent"
                >
                    <mat-form-field
                        class="fuse-mat-dense fuse-mat-rounded fuse-mat-bold w-full"
                        [subscriptSizing]="'dynamic'"
                    >
                        <textarea
                            matInput
                            cdkTextareaAutosize
                            #messageInput
                        ></textarea>
                    </mat-form-field>
                    <div class="my-px ml-4 flex h-11 items-center">
                        <button
                            mat-icon-button
                            type="button"
                            (click)="sendMessage(messageInput.value)"
                        >
                            <mat-icon svgIcon="mat_solid:send"></mat-icon>
                        </button>
                    </div>
                </div>
            }
        </div>

    </div>
</fuse-drawer>
